import Service from "./Service";
const baseurl = '/api/historia-situaciones-personales';
export default {
    index() {
        return Service.get(`${baseurl}`);
    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj); 
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showByHc(id) {
        return Service.get(`${baseurl}/show-by-hc/${id}`);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    },
    showByIdUsuario(id) {
        return Service.get(`${baseurl}/show-by-id_usuario/${id}`);
    }
}